import axios from "axios";
import metrics from "../common/metrics";
import notify from "devextreme/ui/notify";
import { getLanguage } from "../utilities/getLanguage";
//const token = window.localStorage.getItem("state") != null ? window.localStorage.getItem("state").loginReducer.Token : '';

let token = window.localStorage.getItem("token");
let options = {
  baseURL: metrics.baseURL,
  withCredentials: false,
  mode: "cors",
  headers: {
    //"Access-Control-Allow-Origin": "*",
    Authorization: `Bearer ${token}`,
  },
};
const axiosObj = axios.create(options);

axiosObj.interceptors.request.use(
  async config => {
    const token = window.localStorage.getItem("token")
    
    config.headers = { 
      //"Access-Control-Allow-Origin": "*",
      'Authorization': `Bearer ${token}`
    }
    return config;
  },
  error => {
    Promise.reject(error)
});


export const callApiGet = axiosObj.get;
export const callApiPost = axiosObj.post;
export const callApiPut = axiosObj.put;
export const callApiDelete = axiosObj.delete;
export const callApiPatch = axiosObj.patch;

const getHeaderVersion = (version) => ({
  headers: { "Accept-Version": `v${version}` },
});
const getUrl = (url, version = 1) => ({
  url,
  urlOptions: getHeaderVersion(version),
});
 
export async function callAPI(
  axiosFunc,
  urlParam,
  param,
  nextFunc,
  silent = false,
  errFunc
) {
  const { url, urlOptions } = getUrl(urlParam);
  let newParam;
  if ([callApiGet, callApiDelete].includes(axiosFunc)) {
    newParam = { params: param, ...urlOptions };
  } else {
    newParam = param;
  }
  try {
    const res = await axiosFunc(url, newParam, urlOptions);
    if (!silent) {
      //enqueueSnackbar(res?.data?.message, { variant: "success" });
    }
    if (nextFunc) {
        
        
        await nextFunc(res);

    }
  } catch (err) {
    notify('İnternet Bağlantısı Zaman Aşımı.', 'error', 5000);

    if (err?.response) {
      if (![403].includes(err?.response?.status)) {
        if (errFunc) {
          await errFunc(err);
        } else {
          if([401].includes(err?.response?.status)){
             notify('Oturum süreniz dolmuştur.Giriş sayfasına yönlendiriliyorsunuz.', 'error', 3000);
             sessionStorage.clear();
             window.localStorage.clear();
             window.location.href = "#!/login";
          }

        }
      }
    } else {
      sessionStorage.clear();
      window.localStorage.clear();
      window.location.href = "#!/login";
      notify(JSON.stringify(err), 'error', 3000);
    }
  }
}

export default axiosObj;
